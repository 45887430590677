import React, { useEffect, useState } from "react"
import { client, priceClient } from "../../apollo/client"
import { GET_ORDERS, GET_TOKEN_PRICES } from "../../apollo/queries"
import { OrderRow } from "./OrderRow"
import styled from "styled-components"
import { ArrowRight } from "react-feather"
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid"

const OrderTable = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.color};
  margin: 20px;
  border: 1px solid #a5a5a5;
  flex: 1;
  align-self: start;
  max-width: auto;
`

const OrderTableContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

const TableHeader = styled.div`
  flex: 1;
  min-width: 6vw;
  font-weight: bold;
  text-align: center;
  padding: 20px 10px;
  border: 1px solid #4b4b4b;
`

const Headers = styled.div`
  display: flex;
`

const StyledSelect = styled.select`
  background: #5f5f5f;
  color: #fff;
  padding: 0 10px;
  font-size: 24px;
  border-bottom: 2px solid white;
  margin-left: 20px;
`

const StyledOption = styled.option`
  padding: 0 5px;
`

const OpenStat = styled.div`
  font-weight: bold;
  margin-left: 50px;
`

const format18 = (amount) => {
  return (amount / 1e18).toFixed(2)
}

const getPrice = (x) => {
  return x.minReturn / x.inputAmount
}

function comparePrice(token, reverse) {
  const key = token==='input' 
            ? function(x){
                return x.minReturn / x.inputAmount
              }
            : function(x) {
              return 1 / (x.minReturn / x.inputAmount)
            }
  reverse = !reverse ? 1 : -1;
  return function(a,b){
    return a=key(a), b=key(b), reverse * ((a>b)-(b>a))
  }

}

function sortBy(field,reverse,primer1, primer2){
 const key = primer1? 
  function(x){
    return primer2? primer2(primer1(x[field])) : primer1(x[field]) 
  } : function(x){
    return primer2? primer2(x[field]) : x[field]
  }
  reverse = !reverse ? 1 : -1;
  return function(a,b){
    return a=key(a), b=key(b), reverse * ((a>b)-(b>a))
  }
}

const getUniqueOrders = (orders) => {
  const uniqueOrders = orders.reduce((unique, o) => {
    if (!unique.some((obj) => obj.id === o.id)) {
      unique.push(o)
    }
    return unique
  }, [])
  return uniqueOrders
}

export const OrdersView = () => {
  const [token1, setToken1] = useState("tomb")
  const [token2, setToken2] = useState("ftm")
  const [exchange, setExchange] = useState("spookyswap")
  const [separate, setSeparate] = useState(true)
  const [showMinPrice, setShowMinPrice] = useState("limitPrice")
  const [orderBy, setOrderBy] = useState({factor:'price',token:'input',reverse:false})

  const [prices, setPrices] = useState({
    tomb: 0,
    based: 0,
    ftm: 0,
    mai: 0,
  })

  const [token1fortoken2, setToken1fortoken2] = useState([])
  const [token2fortoken1, setToken2fortoken1] = useState([])
  const [combinedOrders, setCombinedOrders] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchPrices = async () => {
      const result = await priceClient.query({
        query: GET_TOKEN_PRICES,
      })
      let tokenPrices = {}
      result.data.tokens.forEach((token) => {
        const name =
          token.symbol === "WFTM"
            ? "ftm"
            : token.symbol === "QI"
              ? "mai"
              : token.symbol.toLowerCase()
        tokenPrices[name] = token.tokenDayData[0].priceUSD
      })
      setPrices(tokenPrices)
    }

    const fetchOrders = async () => {
      if (isLoading) return
      setIsLoading(true)
      const result = await client.query({
        query: GET_ORDERS(token1, token2, exchange),
      })
      
      let token1fortoken2 = result.data.token1fortoken2;
      let token2fortoken1 = result.data.token2fortoken1;

      if(orderBy.factor === 'price'){
        setToken1fortoken2(token1fortoken2.sort(comparePrice(orderBy.token, orderBy.reverse)))
        setToken2fortoken1(token2fortoken1.sort(comparePrice(orderBy.token, orderBy.reverse)))
      }

      if(orderBy.factor==='amount'){
        setToken1fortoken2(token1fortoken2.sort(sortBy('inputAmount',orderBy.reverse,format18,parseFloat)))
        setToken2fortoken1(token2fortoken1.sort(sortBy('inputAmount',orderBy.reverse,format18,parseFloat)))
      }
      
      if(orderBy.factor==='minReturn'){
        setToken1fortoken2(token1fortoken2.sort(sortBy('minReturn',orderBy.reverse,format18,parseFloat)))
        setToken2fortoken1(token2fortoken1.sort(sortBy('minReturn',orderBy.reverse,format18,parseFloat)))
      }

      if(orderBy.factor==="createdAt"){
        setToken1fortoken2(token1fortoken2.sort(sortBy('createdAt',orderBy.reverse)))
        setToken2fortoken1(token2fortoken1.sort(sortBy('createdAt',orderBy.reverse)))
      }
      
      const concatenatedOrders = [].concat(
        result.data.token1fortoken2,
        result.data.token2fortoken1
      )
      const uniqueOrders = getUniqueOrders(concatenatedOrders)
      setCombinedOrders(uniqueOrders.sort(comparePrice))

      setIsLoading(false)
      client.resetStore()
    }

    fetchPrices()
    fetchOrders()
    const timer = setInterval(() => {
      fetchPrices()
      fetchOrders()
    }, 5000)

    return () => clearInterval(timer)
  }, [token1, token2, exchange, orderBy])


  const flipToken = () => {
    setToken1(token2)
    setToken2(token1)
  }

  const padding = exchange !== 'all' ? 'pl-2': 'px-1 text-center';
  return (
    <div style={{ flex: 1 }}>
      <div style={{ display: "flex" }}>
        <StyledSelect
          value={token1}
          onChange={(e) => {
            if (e.target.value === "all" && token2 === "all") setSeparate(false)
            setToken1fortoken2([])
            setToken2fortoken1([])
            setCombinedOrders([])
            setToken1(e.target.value)
          }}
        >
          <StyledOption value="tomb">Tomb</StyledOption>
          <StyledOption value="based">Based</StyledOption>
          <StyledOption value="ftm">FTM</StyledOption>
          <StyledOption value="mai">MAI</StyledOption>
          <StyledOption value="all">All</StyledOption>
        </StyledSelect>
        <ArrowRight
          onClick={() => flipToken()}
          style={{ marginLeft: "20px" }}
        />
        <StyledSelect
          value={token2}
          onChange={(e) => {
            if (e.target.value === "all" && token1 === "all") setSeparate(false)
            setToken1fortoken2([])
            setToken2fortoken1([])
            setCombinedOrders([])
            setToken2(e.target.value)
          }}
        >
          <StyledOption value="tomb">Tomb</StyledOption>
          <StyledOption value="based">Based</StyledOption>
          <StyledOption value="ftm">FTM</StyledOption>
          <StyledOption value="mai">MAI</StyledOption>
          <StyledOption value="all">All</StyledOption>
        </StyledSelect>
        <StyledSelect
          value={exchange}
          onChange={(e) => {
            setToken1fortoken2([])
            setToken2fortoken1([])
            setCombinedOrders([])
            setExchange(e.target.value)
          }}
        >
          <StyledOption value="tombswap">TombSwap</StyledOption>
          <StyledOption value="spookyswap">SpookySwap</StyledOption>
          <StyledOption value="all">All</StyledOption>
        </StyledSelect>
        {/* <StyledSelect
          value={separate}
          onChange={(e) => setSeparate(e.target.value === "true")}
        >
          {(token1 !== "all" || token2 !== "all") && (
            <StyledOption value="true">Separate</StyledOption>
          )}
          <StyledOption value="false">Combined</StyledOption>
        </StyledSelect> */}
        <StyledSelect
          value={showMinPrice}
          onChange={(e) => setShowMinPrice(e.target.value)}
        >
          <StyledOption value="limitPrice">Limit Price</StyledOption>
          <StyledOption value="minPrice">Min Price</StyledOption>
        </StyledSelect>
        <div style={{ display: "flex" }}>
          <OpenStat>Open Orders: {combinedOrders.length}</OpenStat>
          {/* {(token1 !== "all" || token2 !== "all") && (
            <>
              <OpenStat>Open Sell Orders: {token2fortoken1.length}</OpenStat>
              <OpenStat>Open Buy Orders: {token1fortoken2.length}</OpenStat>
            </>
          )} */}
        </div>
      </div>


      <div className="flex w-full px-2 my-10">
        {
          separate ? (

            <table className="table-auto bg-green border-collapse border border-slate-400 w-full">
              <thead className="cursor-pointer">
                <tr className={`text-left ${padding}`}>
                  <th 
                  onClick={()=>setOrderBy({factor:'price', token:'input',reverse:orderBy.reverse===null?false:!orderBy.reverse})}
                  className={`${padding} py-2 border border-slate-300`}> <span className="flex justify-around">
                    Price ({token2.toUpperCase()} / {token1.toUpperCase()})
                    {(orderBy.factor === 'price' && orderBy.token === 'input'&& orderBy.reverse === true)
                    ? <ChevronUpIcon width="15" />
                    : <ChevronDownIcon width="15" />}
                    </span></th>
                  <th 
                   onClick={()=>setOrderBy({factor:'price', token:'output',reverse:orderBy.reverse===null?false:!orderBy.reverse})}
                  className={`${padding} py-2 border border-slate-300`}><span className="flex justify-around">
                    Price ({token1.toUpperCase()} / {token2.toUpperCase()})
                    {(orderBy.factor === 'price' && orderBy.token === 'output'&& orderBy.reverse === true)
                    ? <ChevronUpIcon width="15" />
                    : <ChevronDownIcon width="15" />}
                    </span></th>
                  <th 
                  onClick={()=>setOrderBy({factor:'amount',reverse:orderBy.reverse===null?false:!orderBy.reverse})}
                  className={`${padding} py-2 border border-slate-300`}>
                    <span className="flex justify-around">Amount ({token1.toUpperCase()})
                    {(orderBy.factor === 'amount'&& orderBy.reverse === true)
                    ? <ChevronUpIcon width="15" />
                    : <ChevronDownIcon width="15" />}
                     </span></th>
                  <th
                    onClick={()=>setOrderBy({factor:'amount',reverse:orderBy.reverse===null?false:!orderBy.reverse})}
                     className={`${padding} py-2 border border-slate-300`}><span className="flex justify-around">
                       Total $ 
                       {(orderBy.factor === 'amount'&& orderBy.reverse === true)
                      ? <ChevronUpIcon width="15" />
                      : <ChevronDownIcon width="15" />}
                     </span>
                     </th>
                  <th 
                  onClick={()=>setOrderBy({factor:'minReturn',reverse:orderBy.reverse===null?false:!orderBy.reverse})}
                  className={`${padding} py-2 border border-slate-300`}>
                    <span className="flex justify-around">
                      Min Received ({token2.toUpperCase()})
                      {(orderBy.factor === 'minReturn'&& orderBy.reverse === true)
                      ? <ChevronUpIcon width="15" />
                      : <ChevronDownIcon width="15" />}
                       </span>
                       </th>
                  {exchange == 'all' && <th className={`${padding} py-2 border border-slate-300`}>Exchange</th>}
                  <th className={`${padding} py-2 border border-slate-300`}>TX &nbsp; | &nbsp; Wallet | &nbsp; DE</th>
                  <th className={`${padding} py-2 border border-slate-300`}>Name</th>
                  <th 
                    onClick={()=>setOrderBy({factor:'createdAt',reverse:orderBy.reverse===null?false:!orderBy.reverse})}
                    className={`${padding} py-2 border border-slate-300`}>
                    <span className="flex justify-around">Date Submitted 
                    {(orderBy.factor === 'createdAt'&& orderBy.reverse === true)
                    ? <ChevronUpIcon width="15" />
                    : <ChevronDownIcon width="15" />
                    }
                    
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>

                {
                  token1fortoken2.length ? (
                    token1fortoken2.map((order, i) => (
                      <OrderRow
                        key={order.id}
                        token1={token1}
                        token2={token2}
                        order={order}
                        prices={prices}
                        showExchange={exchange === "all"}
                        showUnits={false}
                        showMinPrice={showMinPrice === "minPrice"}
                        address={order.owner}
                        index={i}
                      />
                    ))
                  ) : (
                    <tr><td colSpan="8" style={{ textAlign: "center" }}>{isLoading ? 'loading...':'No Orders Found'}</td></tr>
                  )}
              </tbody>
            </table>

          ) : (
            <table className="bg-green border-collapse border border-slate-400 w-full">
              <thead className="bg-green-900">
              <tr className={`text-left ${padding}`}>
                  <th className={`${padding} py-2 border border-slate-300`}> Price ({token2.toUpperCase()} / {token1.toUpperCase()})</th>
                  <th className={`${padding} py-2 border border-slate-300`}>Price ({token1.toUpperCase()} / {token2.toUpperCase()})</th>
                  <th onClick={()=>setOrderBy('amount')} className={`${padding} py-2 border border-slate-300`}>Amount ({token1.toUpperCase()})</th>
                  <th className={`${padding} py-2 border border-slate-300`}>Total $</th>
                  <th className={`${padding} py-2 border border-slate-300`}>Min Received ({token2.toUpperCase()})</th>
                  {exchange == 'all' && <th className={`${padding} py-2 border border-slate-300`}>Exchange</th>}
                  <th className={`${padding} py-2 border border-slate-300`}>TX &nbsp; | &nbsp; Wallet</th>
                  <th className={`${padding} py-2 border border-slate-300`}>Name</th>
                  <th className={`${padding} py-2 border border-slate-300`}>Date Submitted</th>
                </tr>
              </thead>
              <tbody>

                {
                  combinedOrders.length ? (
                    combinedOrders.map((order, i) => (
                      <OrderRow
                        key={order.id}
                        token1={token1}
                        token2={token2}
                        order={order}
                        prices={prices}
                        showExchange={exchange === "all"}
                        showUnits={false}
                        showMinPrice={showMinPrice === "minPrice"}
                        address={order.owner}
                        index={i}
                      />
                    ))
                  ) : (
                    <tr><td colSpan="8" style={{ textAlign: "center" }}>{isLoading ? 'loading...':'No Orders Found'}</td></tr>
                  )}
              </tbody>
            </table>
          )
        }
        </div>
    
    </div>
  )
}
