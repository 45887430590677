import React, { useState, useEffect } from "react";
import App from "./App";
import Login from "./Login";

import { supabase } from '../utils/Supabase';

export default function AppLoginRouter() {
  const [session, setSession] = useState<any>(null);
  const [sessionLoaded, setSessionLoaded] = useState<any>(false);
  const [loggedIn, setLoggedIn] = useState<any>(false);

  useEffect(() => {
    setSession(supabase.auth.session());

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
    setSessionLoaded(true);
  }, []);

  useEffect(() => {
    setLoggedIn(true);
  }, [session]);

  if (sessionLoaded) {
    if (loggedIn && session) {
      return (
        <App />
      );
    } else {
      return (
        <Login />
      );
    }
  } else {
    return (
      <div>Loading...</div>
    );
  }

}

