import React from 'react'
// import { ArrowRightIcon } from '@heroicons/react/outline'
import { Order } from '@gelatonetwork/limit-orders-lib'
import { useCurrency } from '../../hooks/Tokens'
import { Currency } from 'tombswap-sdk'
import { ethers } from 'ethers'
// import CurrencyLogo from '../CurrencyLogo'
// import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline'
import { tryParseAmount } from '../../state/swap/hooks'
import { useTradeExactIn } from '../../hooks/Trades'
// import { useGasOverhead, CurrencyAmount, useCurrency as GuseCurrency } from "@gelatonetwork/limit-orders-react"
// import { formatUnits } from '@ethersproject/units'

const isNativeToken = (address: string) => {
    return address.toLowerCase() === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
}

export default function OrderItems({
    order,
    showExecutionTime,
    showLink,
    showCancel,
    columns, index
}: {
    order: Order,
    showExecutionTime?: boolean,
    showLink?: boolean,
    showCancel?: boolean,
    columns: number,
    index?: number,
}) {
    let inputTokenDetails = useCurrency(order.inputToken)
    if (isNativeToken(order.inputToken)) {
        inputTokenDetails = Currency.ETHER
    }
    let outputTokenDetails = useCurrency(order.outputToken)
    if (isNativeToken(order.outputToken)) {
        outputTokenDetails = Currency.ETHER
    }

    const inputTokenName = inputTokenDetails?.symbol
        ? inputTokenDetails?.symbol
        : isNativeToken(order.inputToken)
            ? Currency.ETHER.symbol || ''
            : 'Unknown'
    const outputTokenName = outputTokenDetails?.symbol
        ? outputTokenDetails.symbol
        : isNativeToken(order.outputToken)
            ? Currency.ETHER.symbol || ''
            : 'Unknown'
    const limitInputAmount = ethers.utils.formatUnits(order.inputAmount, inputTokenDetails?.decimals || '18')
    const limitOrderAdjustedMinReturn = ethers.utils.formatUnits(
        order.adjustedMinReturn,
        inputTokenDetails?.decimals || '18'
    )
    const limitOrderMinReturn = ethers.utils.formatUnits(order.minReturn, inputTokenDetails?.decimals || '18')

    const inputParsedAmount = tryParseAmount(
        ethers.utils.formatUnits(order.inputAmount, inputTokenDetails?.decimals || '18'),
        useCurrency(order.inputToken) ?? undefined
    )
    const executedTrade = useTradeExactIn(inputParsedAmount, outputTokenDetails ?? undefined)
    const executionPrice = executedTrade?.executionPrice

    // const inputToken = GuseCurrency(order.inputToken);
    // const outputToken = GuseCurrency(order.outputToken);

    // const inputAmount = useMemo(
    //     () =>
    //         inputToken && order.inputAmount
    //             ? CurrencyAmount.fromRawAmount(inputToken, order.inputAmount)
    //             : undefined,
    //     [inputToken, order.inputAmount]
    // );


    // const outputAmount = useMemo(
    //     () =>
    //         outputToken
    //             ? CurrencyAmount.fromRawAmount(outputToken, order.minReturn)
    //             : undefined,
    //     [outputToken, limitOrderAdjustedMinReturn]
    // );

    // const {
    //     gasPrice,
    //     realExecutionPrice, // returns @uniswap/sdk-core Price object
    //     realExecutionPriceAsString,
    // } = useGasOverhead(inputAmount, outputAmount);

    return (

        <div
            className={` ${index ? (index % 2 === 1 ? 'bg-darkButtonBackground' : '') : ''} tableRow1 grid md:grid-cols-${columns} lg:grid-cols-${columns} font-tin uppercase text-xl items-center border-b text-center`}
        >
            <span className="text-xs leading-tight border-r py-3">{inputTokenName?.toUpperCase() ?? '--'}/{outputTokenName?.toUpperCase() ?? '--'}</span>
            <span className="text-xs leading-tight border-r py-3 ">{executionPrice?.toFixed(4) ?? '--'} {`(${executionPrice?.quoteCurrency?.symbol ?? '--'}/${executionPrice?.baseCurrency?.symbol ?? '--'})`}</span>
            <span className="text-xs leading-tight border-r py-3 ">
                {executionPrice?.invert().toFixed(4) ?? '--'}
                {`(${executionPrice?.baseCurrency?.symbol ?? '--'}/${executionPrice?.quoteCurrency?.symbol ?? '--'})`}
            </span>
            <span className="text-xs leading-tight border-r py-3 ">{parseFloat(limitInputAmount).toFixed(4)} {inputTokenName}</span>
            <span className="text-xs leading-tight border-r py-3 ">{parseFloat(limitOrderAdjustedMinReturn).toFixed(4)} {outputTokenName}</span>
            <span className="text-xs leading-tight border-r py-3 ">{parseFloat(limitOrderMinReturn).toFixed(4)} {outputTokenName}</span>
            <span className="text-xs leading-tight border-r py-3 ">0.500 %</span>
            <span className="text-xs leading-tight border-r py-3 ">0.01</span>
            <span className="text-xs leading-tight border-r py-3  ">15 TOMB</span>
            {showExecutionTime && <span className="text-xs leading-tight border-r py-3 ">{getFormattedTime(order.createdAt)}{/*8:34:18 AM 03/17/2022*/}</span>}
            {showLink && <span className="text-xs leading-tight border-r py-3 underline cursor-pointer">
                <a
                    rel="noopener noreferrer"
                    target={'_blank'}
                    href={`https://ftmscan.com/tx/${order?.executedTxHash ?? order.createdTxHash}`}
                    className="btn w-36 h-8 font-bold text-center pt-1 cursor-pointer"
                >
                    LINK
                </a>
            </span>}
            {showCancel && <span className="text-xs leading-tight py-3 underline cursor-pointer">Cancel</span>}
        </div>
    )
}

const getFormattedTime = (time: string, locale = 'en-US') => {
    const parsedTime = new Date(parseInt(time + '000'))
    const formattedDate = parsedTime.toLocaleDateString(locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    })
    const formattedTime = parsedTime.toLocaleTimeString(locale)

    return `${formattedTime} ${formattedDate}`
}
