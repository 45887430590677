import { CurrencyAmount } from "tombswap-sdk";
import { useCurrencyBalance } from "../../state/wallet/hooks";
import React, { useCallback, useEffect, useState } from "react";
import CurrencyInputPanel from "../../components/CurrencyInputPanel";
import useToggledVersion, { Version } from "../../hooks/useToggledVersion";
import useWrapCallback, { WrapType } from "../../hooks/useWrapCallback";
import { Field } from "../../state/swap/actions";
import {
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from "../../state/swap/hooks";

import { maxAmountSpend } from "../../utils/maxAmountSpend";
import { TOMB, MAI } from "../../constants";
import { Input as NumericalInput } from "../NumericalInput";
import { useActiveWeb3React } from "../../hooks";

export default function Swap() {
  const { account } = useActiveWeb3React();
  const {
    independentField,
    typedValue,
    limitValue,
    percentage,
  } = useSwapState();
  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
  } = useDerivedSwapInfo();
  const { wrapType } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  );
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  //   const { address: recipientAddress } = useENSAddress(recipient)
  const toggledVersion = useToggledVersion();
  const trade = showWrap
    ? undefined
    : {
      [Version.v2]: v2Trade,
    }[toggledVersion];

  const parsedAmounts = showWrap
    ? {
      [Field.INPUT]: parsedAmount,
      [Field.OUTPUT]: parsedAmount,
    }
    : {
      [Field.INPUT]:
        independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
      [Field.OUTPUT]:
        independentField === Field.OUTPUT
          ? parsedAmount
          : trade?.outputAmount,
    };

  const {
    onCurrencySelection,
    onUserInput,
    onUserLimitInput,
  } = useSwapActionHandlers();
  //   const isValid = !swapInputError
  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  useEffect(() => {
    onCurrencySelection(Field.INPUT, TOMB);
    onCurrencySelection(Field.OUTPUT, MAI);
  }, [onCurrencySelection]);

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value);
    },
    [onUserInput]
  );
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value);
    },
    [onUserInput]
  );

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ""
      : parsedAmounts[dependentField]?.toSignificant(6) ?? "",
  };

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(
    currencyBalances[Field.INPUT]
  );
  const inputCurrencyBalance = useCurrencyBalance(
    account ?? undefined,
    currencies[Field.INPUT] ?? undefined
  );
  const outputCurrencyBalance = useCurrencyBalance(
    account ?? undefined,
    currencies[Field.OUTPUT] ?? undefined
  );

  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);

  console.log(approvalSubmitted);

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false); // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency);
    },
    [onCurrencySelection]
  );

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact());
  }, [maxAmountInput, onUserInput]);

  const handleHalfInput = useCallback(() => {
    let half = 0;
    let maxAmount = maxAmountInput?.toExact();
    if (maxAmount) {
      half = parseFloat(maxAmount) / 2;
    }
    onUserInput(Field.INPUT, `${half}`);
  }, [maxAmountInput, onUserInput]);

  const handleOutputSelect = useCallback(
    (outputCurrency) => onCurrencySelection(Field.OUTPUT, outputCurrency),
    [onCurrencySelection]
  );

  const [orderNumber, setOrderNumber] = useState<string>("");

  const handleOrderNumber = useCallback(
    (value: string) => {
      setOrderNumber(value);
    },
    [orderNumber]
  );

  const handleLimitPercentage = useCallback(
    (value: string) => {
      onUserLimitInput(Field.INPUT, value, value);
    },
    [limitValue]
  );

  const executionPair = `${trade?.executionPrice?.baseCurrency?.symbol ??
    "--"} / ${trade?.executionPrice?.quoteCurrency?.symbol ?? "--"}`;
  const executionPrice = `${trade?.executionPrice?.toSignificant(6) ?? "--"}`;
  const executionPairInverted = `${trade?.executionPrice?.invert()?.baseCurrency
    ?.symbol ?? "--"} / ${trade?.executionPrice?.invert()?.quoteCurrency
      ?.symbol ?? "--"}`;
  const executionPriceInverted = `${trade?.executionPrice
    ?.invert()
    ?.toSignificant(6) ?? "--"}`;
  const showInputValue = (current: string) => {
    return current;
  };

  const showOutputValue = (current: string) => {
    return current;
  };

  return (
    <div
      className={`dark:bg-bgBlack border-2 flex-grow px-6 py-4 bg-white ml-2`}
    >
      <div className="grid grid-cols-2 w-100 gap-5">
        <div className="">
          <p>From</p>
          <CurrencyInputPanel
            hideInput={true}
            label={
              independentField === Field.OUTPUT && !showWrap && trade
                ? "From (estimated)"
                : "From"
            }
            value={showInputValue(formattedAmounts[Field.INPUT])}
            showMaxButton={true}
            currency={currencies[Field.INPUT]}
            onUserInput={handleTypeInput}
            onMax={handleMaxInput}
            onHalf={handleHalfInput}
            onCurrencySelect={handleInputSelect}
            otherCurrency={currencies[Field.OUTPUT]}
            id="swap-currency-input"
            isButton={false}
          />
          <p className="text-xs font-thin mt-1">
            Bal: {inputCurrencyBalance?.toSignificant(6) ?? "--"}
          </p>
        </div>
        <div className="">
          <p>TO</p>
          <CurrencyInputPanel
            hideInput={true}
            value={showOutputValue(formattedAmounts[Field.OUTPUT])}
            onUserInput={handleTypeOutput}
            label={
              independentField === Field.INPUT && !showWrap && trade
                ? "To (estimated)"
                : "To"
            }
            showMaxButton={false}
            currency={currencies[Field.OUTPUT]}
            onCurrencySelect={handleOutputSelect}
            otherCurrency={currencies[Field.INPUT]}
            id="swap-currency-output"
            isButton={true}
          />
          <p className="text-xs font-thin mt-1">
            Bal: {outputCurrencyBalance?.toSignificant(6) ?? "--"}
          </p>
        </div>
      </div>
      <div className="flex w-100 mt-2 items-center">
        <div className="w-2/6">
          <p>Input</p>
        </div>
        <div className="flex-grow">
          <div className="bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
            <NumericalInput
              className="bg-bgColor dark:text-white dark:bg-bgBlack text-md token-amount-input token-amount-value"
              value={showOutputValue(formattedAmounts[Field.INPUT])}
              onUserInput={(val) => {
                handleTypeInput(val);
              }}
              isDisabled={false}
            />
          </div>
        </div>
      </div>
      <div className="flex w-100 mt-2 items-center">
        <div className="w-2/6">
          <p></p>
        </div>
        <div className="flex-grow grid grid-cols-4 gap-2 font-semibold">
          <div className="bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2">
            <p className="text-gray-400 text-xs">2000</p>
          </div>
          <div className="bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2">
            <p className="text-gray-400 text-xs">3000</p>
          </div>
          <div className="bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2">
            <p className="text-gray-400 text-xs">4000 </p>
          </div>
          <div className="bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2">
            <p className="text-gray-400 text-xs">5000</p>
          </div>
        </div>
      </div>
      <div className="flex w-100  mt-2 items-center">
        <div className="w-2/6">
          <p>Output</p>
        </div>
        <div className="flex-grow">
          <div className="bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
            <NumericalInput
              className="bg-bgColor dark:text-white dark:bg-bgBlack text-md token-amount-input token-amount-value"
              value={showOutputValue(formattedAmounts[Field.OUTPUT])}
              onUserInput={(val) => {
                handleTypeOutput(val);
              }}
              isDisabled={false}
            />
          </div>
        </div>
      </div>
      <div className="flex w-100  mt-2 items-center">
        <div className="w-2/6">
          <p></p>
        </div>
        <div className="flex-grow grid grid-cols-4 gap-2 font-semibold">
          <div className="bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2">
            <p className="text-gray-400 text-xs">2000</p>
          </div>
          <div className="bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2">
            <p className="text-gray-400 text-xs">3000</p>
          </div>
          <div className="bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2">
            <p className="text-gray-400 text-xs">4000 </p>
          </div>
          <div className="bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2">
            <p className="text-gray-400 text-xs">5000</p>
          </div>
        </div>
      </div>
      <div className="flex w-100  mt-2 items-center">
        <div className="w-2/6">
          <p>{executionPair}</p>
        </div>
        <div className="flex-grow bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
          <p>{executionPrice}</p>
        </div>
      </div>
      <div className="flex w-100  mt-2 items-center">
        <div className="w-2/6">
          <p>{executionPairInverted}</p>
        </div>
        <div className="flex-grow bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
          <p>{executionPriceInverted}</p>
        </div>
      </div>
      <div className="flex w-100  mt-2 items-center">
        <div className="w-2/6">
          <p>Min Recieve</p>
        </div>
        <div className="flex-grow bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
          <p>18</p>
        </div>
      </div>
      <div className="flex w-100  mt-2 items-center">
        <div className="w-2/5">
          <p>Above Market</p>
        </div>
        <div className="flex-grow bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
          <p>18 %</p>
        </div>
      </div>
      <div className="flex w-100  mt-2 items-center">
        <div className="w-2/6">
          <p>Orders</p>
        </div>
        <div className="flex-grow bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
          <NumericalInput
            className="bg-bgColor dark:bg-bgBlack dark:text-white text-md token-amount-input token-amount-value"
            value={orderNumber ?? "0"}
            onUserInput={(val) => {
              handleOrderNumber(val);
            }}
            isDisabled={false}
          />
        </div>
      </div>
      <div className="flex w-100  mt-2 items-center">
        <div className="w-2/5">
          <p>% Deviation</p>
        </div>
        <div className="flex-grow flex justify-end items-center h-10 px-2 text-right font-semibold">
          <p className="font-thin">Randomise</p>
          <input
            className="text-indigo-500 w-5 h-5 ml-2 focus:ring-indigo-400 focus:ring-opacity-25 border border-gray-300 rounded"
            type="checkbox"
          />
        </div>
      </div>
      <div className="flex w-100  mt-2 items-center font-semibold">
        <div className="w-2/4 bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
          <NumericalInput
            className="bg-bgColor dark:text-white dark:bg-bgBlack text-md token-amount-input token-amount-value"
            value={percentage ?? "0"}
            onUserInput={(val) => {
              handleLimitPercentage(val);
            }}
            isDisabled={false}
          />
        </div>
        <div className="w-2/4 bg-slate-50 flex justify-center items-center h-9 px-2">
          <p>{parseFloat(percentage ? percentage : "0.00").toFixed(2)}</p>
        </div>
      </div>
      <div className="flex mt-4">
        <div className="border-2 px-8 py-3 mr-6">Reset</div>
        <div className="border-2 border-gray-700 bg-bgColor dark:bg-border dark:text-black flex-grow flex items-center justify-center hover:bg-gray-400">
          Confirm All Orders
        </div>
      </div>
    </div>
  );
}

//465: {betterTradeLinkVersion && <BetterTradeLink version={betterTradeLinkVersion} />}
