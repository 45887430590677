import React, { Suspense, useState } from "react";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import styled from "styled-components";
import GoogleAnalyticsReporter from "../components/analytics/GoogleAnalyticsReporter";
// import Header from '../components/Header'
// import Popups from '../components/Popups'
import Web3ReactManager from "../components/Web3ReactManager";
import DarkModeQueryParamReader from "../theme/DarkModeQueryParamReader";
import AddLiquidity from "./AddLiquidity";
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity
} from "./AddLiquidity/redirects";
import Dashboard from "./Dashboard";
import LimitOrders from "./Dashboard/limitOrders";
import Pool from "./Pool";
import PoolFinder from "./PoolFinder";
import Swap from "./Swap";
import RemoveLiquidity from "./RemoveLiquidity";
import { RedirectPathToSwapOnly, RedirectToSwap } from "./Swap/redirects";
import { RedirectOldRemoveLiquidityPathStructure } from "./RemoveLiquidity/redirects";
import Header from "../components/Header";
import SettingDialog from "../components/SettingsDailog";

import { useIsDarkMode } from "../state/user/hooks";
// const Marginer = styled.div`
//   margin-top: 5rem;
// `;

const AppWrapper = styled.div`
  display: block;
`;

export default function App() {
  const isDarkMode = useIsDarkMode();
  const [isSettingOpen, setIsSettingOpen] = useState(0)
  if (isDarkMode) {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }
  return (
    <Suspense fallback={null}>
      <Router>
        <Route component={GoogleAnalyticsReporter} />
        <Route component={DarkModeQueryParamReader} />
        <AppWrapper className="dark:bg-bgBlack dark:text-white ml-16">
          <Header isSettingOpen={isSettingOpen} setIsSettingOpen={setIsSettingOpen} />
          <Web3ReactManager>
            <Switch>
              <Route exact strict path="/orders" component={Dashboard} />
              <Route exact strict path="/swap" component={LimitOrders} />
              <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
              <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
              <Route exact strict path="/find" component={PoolFinder} />
              <Route exact strict path="/pool" component={Pool} />
              <Route exact strict path="/create" component={RedirectToAddLiquidity} />
              <Route exact path="/add" component={AddLiquidity} />
              <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
              <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
              <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
              <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
              <Route component={Dashboard} />
            </Switch>
          </Web3ReactManager>
          {/* <Marginer /> */}
          <SettingDialog isSettingOpen={isSettingOpen} setIsSettingOpen={setIsSettingOpen} />
        </AppWrapper>
      </Router>
    </Suspense>
  );
}
