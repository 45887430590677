import React from "react";
import { useGelatoLimitOrdersHistory } from "@gelatonetwork/limit-orders-react";
import OrderItems from "../../components/LimitOrder/OrderItems";
import Swap from '../Dashboard/swap'

function DashboardSwapContent(props: any) {


  const { open, cancelled, executed, expired } = useGelatoLimitOrdersHistory(false);

  return (
    <>
      <div className="flex-grow">
        <div className="flex bg-bgColor dark:bg-bgBlack pb-3">
          <div
            className={` bg-white dark:bg-bgBlack border-t-2 ${props.openTab == 1 ? "w-4/5" : "w-full"
              }`}
          >
            <div
              className={`tableRow1 grid font-semibold uppercase text-center items-center border-y heading-border ${props.openTab == 1
                ? "md:grid-cols-9 lg:grid-cols-9"
                : props.openTab == 3
                  ? "md:grid-cols-11 lg:grid-cols-11"
                  : "md:grid-cols-12 lg:grid-cols-12"
                }
            `}
            >
              <span className="text-xs leading-tight border-r h-full py-3">
                pair
              </span>
              <span className="text-xs leading-tight border-r h-full py-3">
                {props.openTab == 1 ? `Price (A1/A2)` : `Price`}
              </span>
              <span className="text-xs leading-tight border-r h-full py-3">
                {props.openTab == 1 ? `Price (A2/A1)` : `Price Inverted`}
              </span>
              <span className="text-xs leading-tight border-r h-full py-3">
                Amount
              </span>
              <span className="text-xs leading-tight border-r h-full py-3">
                Amount Received
              </span>
              <span className="text-xs leading-tight border-r h-full py-3">
                Min Received
              </span>
              <span className="text-xs leading-tight border-r h-full py-3">
                % Above Market
              </span>
              <span className="text-xs leading-tight border-r h-full py-3">
                Price Impact
              </span>
              <span className="text-xs leading-tigh border-r h-full py-3">
                LP Fee
              </span>
              {props.openTab != 1 && (
                <>
                  <span className="text-xs leading-tight border-r h-full py-3">
                    Date Submitted
                  </span>
                  <span className="text-xs leading-tight border-r h-full py-3">
                    FTM Scan
                  </span>
                  {props.openTab != 3 && (
                    <>
                      <span className="text-xs leading-tight h-full py-3">
                        Cancel
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
            {props.openTab == 1 && (
              <>
                {[
                  ...open.pending,
                  ...open.confirmed,
                  ...executed,
                  ...cancelled.pending,
                  ...cancelled.confirmed,
                  ...expired,
                ].map((order, i) => (
                  <OrderItems key={order.id} order={order} index={i} columns={9} />
                ))}
              </>
            )}
            {props.openTab == 2 && (
              <>
                {[...open.pending, ...open.confirmed].map((order, i) => (
                  <OrderItems key={order.id} order={order} index={i} columns={12} />
                ))}
              </>
            )}
            {props.openTab == 3 && (
              <>
                {executed.map((order, i) => {
                  return (
                    <OrderItems
                      key={order.id}
                      order={order}
                      showExecutionTime={true}
                      showLink={true}
                      columns={11}
                      index={i}
                    />
                  );
                })}
              </>
            )}
            {props.openTab == 4 && (
              <>
                <div className="tableContent">
                  {Array(40)
                    .fill(1)
                    .map((e, i) => (
                      <div
                        key={i}
                        className="tableData tableRow1 grid md:grid-cols-12 lg:grid-cols-12 font-tin uppercase text-xl items-center border-b text-center"
                      >
                        <span className="text-xs leading-tight border-r py-3">
                          TOMB/MAI
                        </span>
                        <span className="text-xs leading-tight border-r py-3 ">
                          1.3923 (MAI/TOMB)
                        </span>
                        <span className="text-xs leading-tight border-r py-3 ">
                          0.718238 (TOMB/MAI)
                        </span>
                        <span className="text-xs leading-tight border-r py-3 ">
                          3,000.00 TOMB
                        </span>
                        <span className="text-xs leading-tight border-r py-3 ">
                          2,165.487 MAI
                        </span>
                        <span className="text-xs leading-tight border-r py-3 ">
                          2,143.000 MAI
                        </span>
                        <span className="text-xs leading-tight border-r py-3 ">
                          0.500 %
                        </span>
                        <span className="text-xs leading-tight border-r py-3 ">
                          0.01
                        </span>
                        <span className="text-xs leading-tight border-r py-3 ">
                          15 TOMB
                        </span>
                        <span className="text-xs leading-tight border-r py-3 ">
                          8:34:18 AM 03/17/2022
                        </span>
                        <span className="text-xs leading-tight border-r py-3 underline ">
                          Link
                        </span>
                        <span className="text-xs leading-tight py-3 underline ">
                          Cancel
                        </span>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>
          <div
            className={`dark:bg-bgBlack border-2 flex-grow px-6 py-4 bg-white ml-2 ${props.openTab == 1 ? "block" : "hidden"
              }`}
          >
            <Swap />
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardSwapContent;
