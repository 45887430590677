import React, { useState, useCallback, useEffect } from "react";
import darklogo from "../../assets/svg/dark_logo.svg";
import lightlogo from "../../assets/svg/light_logo.svg";
import lightColor from "../../assets/svg/lightColor.svg";
import darkColor from "../../assets/svg/darkColor.svg";
import { useSelector } from "react-redux";
// import Modal from "../Modal";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import {
  useDarkModeManager,
  useWhiteListManager,
  useIsWhiteList,
  useWhiteListDeleter,
  useLocalWhiteListCleaner
} from "../../state/user/hooks";
import { Input as AlphaNumericInput } from "../AlphaNumericInput";

import { supabase } from '../../utils/Supabase';
import { RealtimeSubscription, SupabaseRealtimePayload } from "@supabase/supabase-js";

export default function SettingDialog(props: any) {
  const [openTab, setOpenTab] = useState(1);
  const { isSettingOpen, setIsSettingOpen } = props;
  const [darkMode, toggleSetDarkMode] = useDarkModeManager();
  const addWhiteList = useWhiteListManager();
  const clearLocalWhiteList = useLocalWhiteListCleaner();
  const deleteWhiteList = useWhiteListDeleter();
  const whiteList = useIsWhiteList()
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  let QTT_WhitelistedAddressSubscription: RealtimeSubscription;

  async function addToList() {
    if (address == "" || name == "") return;

    setAddress("");
    setName("");

    try {
      const { error } = await supabase
        .from('QTT.WhitelistedAddress')
        .upsert({ name: name, address: address }, { onConflict: 'address' })

      if (error) {
        console.log('Realtime update error', error)
      }

    } catch (err) {
      console.log('Realtime update exception', err)
    }
  }

  function handleSupabaseAddressUpdate(payload: SupabaseRealtimePayload<any>) {
    let type = payload.eventType;
    let index = whiteList?.length ? `${whiteList.length}` : '0';

    switch (type) {
      case 'DELETE':
        const { old: deletedData } = payload;
        deleteWhiteList(deletedData.address, deletedData.name);
        break;
      case 'INSERT':
        const { new: insertedData } = payload;
        addWhiteList(parseInt(index), insertedData.address, insertedData.name);
        break;
      case 'UPDATE':
        const { new: uptadedData } = payload;
        addWhiteList(parseInt(index), uptadedData.address, uptadedData.name);
        break;
      default:
        return;
    }
  }

  async function deleteAddress(address: string, name: string) {
    try {
      const { error } = await supabase
        .from('QTT.WhitelistedAddress')
        .delete()
        .eq('name', name)
        .eq('address', address)

      if (error) {
        console.log('Realtime delete error', error)
      }
    } catch (err) {
      console.log('Realtime delete exception', err)
    }
  }

  async function fetchLatestWhitelistData() {
    try {
      let { data, error } = await supabase
        .from('QTT.WhitelistedAddress')
        .select('*')

      if (error) {
        console.log('data fetch exception', error);
      }

      if (data) {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            addWhiteList(i, data[i].address, data[i].name);
          }
        }
      }
    } catch (err) {
      console.log('data fetch exception', err);
    }

  }

  function subscribeToSupabaseRealtime() {
    if (!QTT_WhitelistedAddressSubscription) {
      QTT_WhitelistedAddressSubscription = supabase
        .from('QTT.WhitelistedAddress')
        .on('*', payload => {
          handleSupabaseAddressUpdate(payload);
        })
        .subscribe()
    }
  }

  useEffect(() => {
    clearLocalWhiteList();
    subscribeToSupabaseRealtime();
    fetchLatestWhitelistData();
  }, [])

  return (
    <DialogOverlay
      className="z-50"
      isOpen={isSettingOpen}
      onDismiss={() => setIsSettingOpen(!isSettingOpen)}
    >
      <DialogContent
        aria-label="setting dialog content"
        className="dark:bg-bgBlack dark:text-white border-2 justify-center pl-0 pr-0"
        style={{ width: "1140px", height: "650px" }}
      >
        <div className="flex px-8">
          <div className="w-1/2 flex justify-start font-normal text-3xl dark:text-white text-black">
            Settings
          </div>

          <div className="w-1/2 flex justify-end pr-5 gap-x-14 mt-4">
            <div className="flex">
              <a href="#_" className="mr-4" onClick={async () => { await supabase.auth.signOut() }}>Logout</a>
            </div>
            <div className="flex">
              <span className="mr-4">Orientation</span>
              <span className={`cursor-pointer`}>
                <img
                  className="mx-2"
                  width={25}
                  height={15}
                  src={darkColor}
                  alt="Light Icon"
                />
              </span>
              <span className={` cursor-pointer`}>
                <img
                  className="mx-2"
                  width={14}
                  height={20}
                  src={lightColor}
                  alt="Light Icon"
                />
              </span>
            </div>
            <div className="flex">
              <span className="mr-4">Mode</span>
              <span
                className={`${darkMode ? "cursor-not-allowed" : ""
                  } cursor-pointer`}
                onClick={() => toggleSetDarkMode()}
              >
                <img
                  className="mx-2"
                  width={13}
                  height={20}
                  src={darklogo}
                  alt="Dark Icon"
                />
              </span>
              <span
                className={`${darkMode ? "" : "cursor-not-allowed"
                  } cursor-pointer`}
                onClick={() => toggleSetDarkMode()}
              >
                <img
                  className="mx-2"
                  width={20}
                  height={20}
                  src={lightlogo}
                  alt="Light Icon"
                />
              </span>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 w-3/4 mx-8 gap-2 mt-8 ">
          <div
            className={`flex w-48 justify-center pt-3 border-t border-[#CECECE] rounded-t-xl border-l border-r pb-2 hover:bg-bgGray dashboard-tab-border ${openTab == 1 ? "bg-white dark:bg-bgGray" : ""
              }`}
            onClick={() => setOpenTab(1)}
          >
            <p>Pricing Pairs</p>
          </div>
          <div
            className={`flex w-48 justify-center pt-3 border-t border-[#CECECE] rounded-t-xl border-l border-r pb-2 hover:bg-bgGray dashboard-tab-border ${openTab == 2 ? "bg-white dark:bg-bgGray" : ""
              }`}
            onClick={() => setOpenTab(2)}
          >
            <p>Whitelist</p>
          </div>
          <div
            className={`flex w-48 justify-center pt-3 border-t border-[#CECECE] rounded-t-xl border-l border-r pb-2 hover:bg-bgGray dashboard-tab-border ${openTab == 3 ? "bg-white dark:bg-bgGray" : ""
              }`}
            onClick={() => setOpenTab(3)}
          >
            <p>Coming Soon</p>
          </div>
          <div
            className={`flex w-48 justify-center pt-3 border-t border-[#CECECE] rounded-t-xl border-l border-r pb-2 hover:bg-bgGray dashboard-tab-border ${openTab == 4 ? "bg-white dark:bg-bgGray" : ""
              }`}
            onClick={() => setOpenTab(4)}
          >
            <p>Coming Soon</p>
          </div>
        </div>
        {openTab == 1 && (
          <>
            <div className="pl-8 w-full border-t border-[#CECECE] pt-7">
              <div className="h-full w-32">
                <div className="ml-2 text-xl mb-5">Pair</div>
                <div className="my-3 w-44 px-2 py-1 text-l tracking-widest	border border-[#CECECE">
                  TOMB/FTM
                </div>
                <div className="my-3 w-44 px-2 py-1 text-l tracking-widest border border-[#CECECE">
                  TSHARE/FTM
                </div>
                <div className="my-3 w-44 px-2 py-1 text-l tracking-widest border border-[#CECECE">
                  TOMB/MAI
                </div>
                <div className="my-3 w-44 px-2 py-1 text-l tracking-widest border border-[#CECECE">
                  TSHARE/MAI
                </div>
                <div className="my-3 w-44 px-2 py-1 text-l tracking-widest border border-[#CECECE">
                  TOMB/BASED
                </div>
                <div className="my-3 w-44 px-2 py-1 text-l tracking-widest border border-[#CECECE">
                  BASED/MAI
                </div>
                <div className="my-3 w-44 px-2 py-1 text-l tracking-widest	 border border-[#CECECE">
                  TOMB/TBOND
                </div>
                <div className="my-3 w-44 px-2 py-1 text-l tracking-widest border border-[#CECECE">
                  MAI/USDC
                </div>
                <div className="my-3 w-44 px-2 py-1 text-l tracking-widest text-center border border-[#CECECE">
                  +
                </div>
              </div>

              <div className="h-full"></div>
            </div>
          </>
        )}
        {openTab == 2 && (
          <>
            <div className="flex border-t w-full mt-7 pl-5">
              <div className="flex w-100 mt-5 items-center">
                <div className="flex gap-x-5 ite">
                  <div className="bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
                    <AlphaNumericInput
                      className="w-96 flex-grow bg-bgColor dark:text-white dark:bg-bgBlack text-base token-amount-input token-amount-value"
                      value={name}
                      onUserInput={(val) => {
                        setName(val);
                      }}
                      isDisabled={false}
                      placeholder="Name"
                    />
                  </div>

                  <div className="bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
                    <AlphaNumericInput
                      className="w-96 bg-bgColor dark:text-white dark:bg-bgBlack text-base token-amount-input token-amount-value"
                      value={address}
                      onUserInput={(val) => {
                        setAddress(val);
                      }}
                      isDisabled={false}
                      placeholder="Wallet Address"
                    />
                  </div>

                  <div className="w-40 border-2 border-gray-700 bg-bgColor dark:bg-border dark:text-black flex justify-center items-center h-10 px-2 text-center font-semibold cursor-pointer"
                    onClick={() => addToList()}
                  >
                    Add
                  </div>

                </div>
              </div>
            </div>

            <div className="flex w-full mt-7" style={{ height: '357px' }}>
              <div
                className="ml-5 flex-grow mt-7 overflow-y-auto mr-2"
              >
                <div className="grid grid-cols-2 border px-2 font-semibold text-center">
                  <div className="py-2 border-r">Wallet Name</div>
                  <div className="py-2">Wallet Address</div>
                </div>
                {whiteList && (
                  <>
                    {Object.keys(whiteList).map((address) => (
                      <div key={address} className="grid grid-cols-2 border border-t-0 px-2 ">
                        <div className="py-2 border-r">{`${whiteList[address]?.name}`}</div>
                        <div className="py-2 ml-2">
                          {`${address}`}
                          <a
                            href="#_"
                            className="ml-2"
                            onClick={() => { deleteAddress(address, whiteList[address]?.name) }}
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </DialogContent>
    </DialogOverlay>
  );
}
