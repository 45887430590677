import React from "react";
import { Link, useLocation } from 'react-router-dom'
// import { isMobile } from 'react-device-detect'
import Web3Status from "../Web3Status";
import { useIsDarkMode } from "../../state/user/hooks";
import TombSwap from "../../assets/svg/logo.svg";
import settingLight from "../../assets/svg/setting-light.svg";
import settingDark from "../../assets/svg/setting-dark.svg";
import darkswap from "../../assets/svg/dark_swap.svg";
import darkorder from "../../assets/svg/dark_order.svg";
import swap from "../../assets/svg/swap.svg";
import order from "../../assets/svg/order.svg";
import { useGelatoLimitOrdersHistory } from "@gelatonetwork/limit-orders-react";
import { useDarkModeManager } from "../../state/user/hooks";
import { useSetOpenTab } from "../../state/dashboard/hooks";

export default function Header(props: any) {
  const { pathname } = useLocation()

  const { isSettingOpen, setIsSettingOpen } = props;
  const isDark = useIsDarkMode();
  const { open } = useGelatoLimitOrdersHistory();
  const [darkMode] = useDarkModeManager();
  const [openSideTab, setOpenSideTab] = useSetOpenTab();

  const totalOpenOrders = [...open.pending, ...open.confirmed].length;
  const isSwap = pathname === '/swap'

  return (
    <div className="flex justify-between py-1 px-5">
      <div className="border-r dark:bg-bgBlack btn-side-tab flex flex-col justify-between">
        <div className="">
          <Link to='/swap'>
            <div
              className={`border border-r-0 border-gray-300 py-10 px-2 cursor-pointer text-center ${!!isSwap ? "bg-bgColor dark:bg-bgGray" : ""
                }`}
              onClick={() => setOpenSideTab("Swap")}
            >
              {darkMode ? (
                <img className="mb-2 mx-auto" src={darkswap} alt="Dark Icon" />
              ) : (
                <img className="mb-2 mx-auto" src={swap} alt="Dark Icon" />
              )}

              <p>Swap</p>
            </div>
          </Link>

          <Link to='/orders'>
            <div
              className={`border border-r-0 py-10 border-gray-300 px-2 cursor-pointer text-center ${!isSwap ? "bg-bgColor dark:bg-bgGray" : ""
                }`}
            // onClick={() => setOpenSideTab("Orders")}
            >
              {darkMode ? (
                <img className="mx-auto mb-2" src={darkorder} alt="Dark Icon" />
              ) : (
                <img className="mx-auto mb-2" src={order} alt="Dark Icon" />
              )}

              <p>Orders</p>
            </div>
          </Link>
          <div
            className="border border-r-0 py-10 border-gray-300 px-2 cursor-pointer text-center"
            onClick={() => setIsSettingOpen(!isSettingOpen)}
          >
            <img className="mx-auto mb-2" src={isDark ? settingDark : settingLight} alt="" />
            <p>Settings</p>
          </div>
        </div>
      </div>
      <div className={`flex justify-between flex-grow ${isSwap ? 'block' : 'hidden'}`}>
        <div className="flex">
          <img
            width={24}
            height={31}
            src={TombSwap}
            alt="Tomb Swap Hig Frequency"
          />
          <p className="ml-2 font-semibold text-md header-font">
            {" "}
            Tomb Swap | High Frequency Mode
          </p>
        </div>
        <div className="flex">
          <p className="underline font-bold">
            Open Orders:{" "}
            <span className="font-thin pl-5">{totalOpenOrders}</span>
          </p>
        </div>
        <div className="flex">
          <p className=" mr-8 font-bold">350,000 MAI</p>
          <p className="mr-8 font-bold">19,900 TBOND</p>
          <p className="mr-8 font-bold">269,000 WFTM</p>
          <p className="mr-8 font-bold">7,720,000 TOMB</p>
          <p className="mr-8 font-bold">129,000 FTM</p>
        </div>
        <div className="flex">
          <div>
            <Web3Status />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => setIsSettingOpen(!isSettingOpen)}
          >
            <img src={isDark ? settingDark : settingLight} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
