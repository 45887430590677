import React, { useState } from "react";
// import { useSelector } from 'react-redux'
// import { AppState } from '../../state'
import DashboardSwapContent from "../../components/swap/DashboardSwapContent";
import { useGelatoLimitOrdersHistory } from "@gelatonetwork/limit-orders-react";

import { useSetOpenTab } from "../../state/dashboard/hooks"
import { OrdersView } from "../OrdersView/OrdersView"

function LimitOrders() {
    // const setOpenSideTab = useSetOpenTab()
    const [openTab, setOpenTab] = useState(1)
    const [openSideTab] = useSetOpenTab()
    const { open } = useGelatoLimitOrdersHistory();
    const totalOpenOrders = [...open.pending, ...open.confirmed].length
    const isSwapTab = openSideTab === 'Swap'

    return (
        <>
            {/* <GelatoLimitOrdersHistoryPanel /> */}
            <div className={`dark:bg-bgBlack dark:text-white bg-bgColor pt-8 ${isSwapTab ? 'block' : 'hidden'}`}>
                <div className={`flex justify-around pb-5 dark:text-white ${isSwapTab ? 'block' : 'hidden'}`}>
                    <div className="font-semibold ">
                        TOMB/FTM <span className="font-thin ml-5">0.97542</span>
                    </div>
                    <div>|</div>
                    <div className="font-semibold">
                        TOMB/MAI <span className="font-thin ml-5">1.65462</span>
                    </div>
                    <div>|</div>
                    <div className="font-semibold">
                        BASED/TOMB <span className="font-thin ml-5">1.65462</span>
                    </div>
                    <div>|</div>
                    <div className="font-semibold">
                        BASED/MAI <span className="font-thin ml-5">1.65462</span>
                    </div>
                    <div>|</div>
                    <div className="font-semibold">
                        TSHARE/FTM <span className="font-thin ml-5">3215.65452</span>
                    </div>
                    <div>|</div>
                    <div className="font-semibold">
                        TSHARE/MAI <span className="font-thin ml-5">3215.65452</span>
                    </div>
                    <div className="font-semibold">
                        TSHARE/MAI <span className="font-thin ml-5">3215.65452</span>
                    </div>
                </div>
                {openSideTab == "Swap" && (
                    <>
                        <div className="grid grid-cols-4 w-3/5 px-4 gap-4">
                            <div
                                className={`flex justify-center pt-3 border-t border-[#CECECE] rounded-t-xl border-l border-r pb-2 hover:bg-bgGray dashboard-tab-border ${openTab == 1 ? "bg-white dark:bg-bgGray" : ""
                                    }`}
                                onClick={() => setOpenTab(1)}
                            >
                                <p>Limit Orders</p>
                            </div>
                            <div
                                className={`flex justify-center pt-3 border-t border-[#CECECE] rounded-t-xl border-l border-r pb-2 hover:bg-bgGray dashboard-tab-border ${openTab == 2 ? "bg-white dark:bg-bgGray" : ""
                                    }`}
                                onClick={() => setOpenTab(2)}
                            >
                                <p>Open Orders - {totalOpenOrders}</p>
                            </div>
                            <div
                                className={`flex justify-center pt-3 border-t border-[#CECECE] rounded-t-xl border-l border-r pb-2 hover:bg-bgGray dashboard-tab-border ${openTab == 3 ? "bg-white dark:bg-bgGray" : ""
                                    }`}
                                onClick={() => setOpenTab(3)}
                            >
                                <p>Past Orders</p>
                            </div>
                            <div
                                className={`flex justify-center pt-3 border-t border-[#CECECE] rounded-t-xl border-l border-r pb-2 hover:bg-bgGray dashboard-tab-border ${openTab == 4 ? "bg-white dark:bg-bgGray" : ""
                                    }`}
                                onClick={() => setOpenTab(4)}
                            >
                                <p>Liquidity</p>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className={`flex ${openSideTab == "Swap" ? "active-swap" : "active-orders"}`}>
                <DashboardSwapContent openTab={openTab} />
            </div>
        </>
    )
}

export default LimitOrders
