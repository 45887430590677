import React, { useState } from "react";
// import { useSelector } from 'react-redux'
// import { AppState } from '../../state'
import DashboardSwapContent from "../../components/swap/DashboardSwapContent";
import { useGelatoLimitOrdersHistory } from "@gelatonetwork/limit-orders-react";

import { useSetOpenTab } from "../../state/dashboard/hooks"
import { OrdersView } from "../OrdersView/OrdersView"

function Dashboard() {
  // const setOpenSideTab = useSetOpenTab()
  const [openTab, setOpenTab] = useState(1)
  const [openSideTab] = useSetOpenTab()
  const { open } = useGelatoLimitOrdersHistory();
  const totalOpenOrders = [...open.pending, ...open.confirmed].length
  const isSwapTab = openSideTab === 'Swap'

  return (
    <OrdersView />
  )
}

export default Dashboard
